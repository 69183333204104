import { Navigate } from 'react-router-dom'
import LoadingPage from '../../../views/LoadingPage/LoadingPage'
import { selectAppPath } from '../utils'
import { useAuthenticatedUser } from '../queries'
import { apiClient } from '@services/api'
import { userStorage } from '@services/utils/local-storage'

export function SelectApp() {
  const {
    data: user,
    isLoading,
    isSuccess
  } = useAuthenticatedUser(apiClient)

  const tokens = userStorage.get()

  if (isLoading && tokens) {
    return <LoadingPage />
  }

  if (isSuccess && tokens) {
    return <Navigate to={selectAppPath(user, localStorage)} replace />
  }

  return <Navigate to="/login" replace />
}
