import styles from './ErrorPage.module.scss'

import SaivaLogo from '../../assets/saiva-logo-white.svg?react'
import Person from '../../assets/person.svg?react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'mysaiva-web-components'

interface ErrorPageProps {
  emptyState?: boolean
}

const ErrorPage = (props: ErrorPageProps) => {
  const navigate = useNavigate()

  const handleReloadPageClick = () => {
    if (props.emptyState) navigate('/')
    else navigate(-1)
  }

  return (
    <div className={styles.errorPageWrapper}>
      <div className={styles.header}>
        <SaivaLogo
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
        <Button
          type={'link'}
          onClick={() => navigate('/')}
          className={styles.contactUsRedirect}
        >
          CONTACT US
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.person}>
            <Person />
          </div>
          <div className={styles.content}>
            <h1>Oops!</h1>
            <h4>
              We are sorry.
              <br />
              Something went wrong.
            </h4>
            <Button
              type="primary"
              onClick={handleReloadPageClick}
              icon={'Refresh'}
            >
              RELOAD PAGE
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
