import './App.scss'
import { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Login, Invitation } from '@authentication/views'
import { useAuthentication } from '@authentication/context'
import {
  ProtectedRoute,
  SelectApp,
  TrackLastApp
} from '@authentication/components'
import LoadingPage from './views/LoadingPage/LoadingPage'
import ErrorPage from './views/ErrorPage/ErrorPage'
import { SaivaProduct } from './types/api'

const WoundsApp = lazy(() => import('mysaiva-wounds/app'))
const UptApp = lazy(() => import('mysaiva-upt/app'))
const TherapyApp = lazy(() => import('mysaiva-therapy/app'))

function App() {
  const { status } = useAuthentication()

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:email" element={<Login />} />
        <Route path="/join/:token" element={<Invitation />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route element={<ProtectedRoute loginState={status} />}>
          <Route path="*" element={<ErrorPage />} />
          <Route
            path="/upt/*"
            element={
              <TrackLastApp appName={SaivaProduct.Upt}>
                <UptApp />
              </TrackLastApp>
            }
          />
          <Route
            path="/wounds/*"
            element={
              <TrackLastApp appName={SaivaProduct.WoundCare}>
                <WoundsApp />
              </TrackLastApp>
            }
          />
          <Route
            path="/therapy/*"
            element={
              <TrackLastApp appName={SaivaProduct.Therapy}>
                <TherapyApp />
              </TrackLastApp>
            }
          />
        </Route>
        <Route path="/" element={<SelectApp />} />
      </Routes>
    </Suspense>
  )
}

export default App
